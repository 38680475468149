<template>
    <div class="EmailTemplateView ViewWrapper">
        <clv-head-meta :title="$tc('entity Email Template', 1)"></clv-head-meta>

        <!-- TRANSPARENT LOCK -->
        <clv-interaction-locker :active="emailTemplateUpdating"></clv-interaction-locker>

        <!-- STATE INDICATORS / LOADERS -->
        <element-loading :active="emailTemplateGetting" :is-full-view="true" :lock-parent="true"></element-loading>
        <element-loading :active="emailTemplateDeleting" :is-full-view="true" :lock-parent="true" :text="$t('Deleting Email Template')"></element-loading>

        <!-- EMPTY STATE 2020-07-19 -->
        <simple-state-not-found :for-view="true" v-if="emailTemplateNotFound">
            <div class="mt-2" slot="action">
                <router-link tag="a" :to="{ name: 'email-templates' }"><i class="fas fa-chevron-left"></i> {{ $t('Browse Email Templates') }}</router-link>
            </div>
        </simple-state-not-found>

        <div v-if="contextDeviceHasSmallScreen" key="small-screen">
            <email-template-small-device-preview v-if="emailTemplate"></email-template-small-device-preview>
        </div>
        <div v-else key="medium-and-above-screen">
            <email-template-header v-if="emailTemplate"
                                   :key="`header-${activeComponentCommonKey}`"></email-template-header>
            <email-template-edit v-if="emailTemplate"
                                 :key="`edit-${activeComponentCommonKey}`"></email-template-edit>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RouterUtils from '@/common/utils/router.utils';
import EmailTemplateSmallDevicePreview from './EmailTemplateSmallDevicePreview';
import EmailTemplateHeader from './EmailTemplateHeader';
import EmailTemplateEdit from './EmailTemplateEdit';

/**
 * EmailTemplate content and design.
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 November 2020
 */
export default {
    name: 'EmailTemplateView',
    components: {
        EmailTemplateSmallDevicePreview,
        EmailTemplateHeader,
        EmailTemplateEdit
    },
    computed: {
        ...mapGetters('emailTemplate', {
            emailTemplateGetting: 'emailTemplateGetting',
            emailTemplateUpdating: 'emailTemplateUpdating',
            emailTemplateDeleting: 'emailTemplateDeleting',
            emailTemplateNotFound: 'emailTemplateNotFound',
            emailTemplate: 'emailTemplate',
            activeComponentCommonKey: 'activeComponentCommonKey'
        }),
        ...mapGetters('application', {
            contextDeviceHasSmallScreen: 'contextDeviceHasSmallScreen'
        })
    },
    watch: {
        '$route': {
            handler: function (to, from) {
                const result = RouterUtils.resourceIdHelper(to, from);
                const id = result.toId;

                if (result.mustInitialize) {
                    this.$store.commit('application/navigationContainerSwitchToFluid');
                    this.$store.dispatch('emailTemplate/initializeModule', id)
                        .then(() => void 0).catch(() => void 0);
                    // No need to commit 'updateActiveComponentCommonKey'
                    // because this operation will force the view to re-mount.
                }
            },
            immediate: true
        }
    },
    beforeMount () {
        this.$store.commit('emailTemplate/updateActiveComponentCommonKey');
    },
    beforeDestroy () {
        this.$store.commit('application/navigationContainerSwitchToDefault');
        this.$store.dispatch('emailTemplate/resetModule').then(() => void 0).catch(() => void 0);
    }
};
</script>
