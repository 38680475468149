<template>
    <div class="EmailTemplateHeader bg-white border-bottom">
        <div class="container-fluid">
            <div class="d-flex justify-content-between align-items-center py-3">
                <div>
                    <p class="mb-1 text-microcopy--70 text-primary">
                        <router-link :to="{ name: 'email-templates' }">
                            <i class="fas fa-chevron-left"></i> {{ $tc('entity Email Template', 2) }}
                        </router-link>
                    </p>
                    <p class="size-5 weight-7 line-height-2 mb-0"><span class="text-muted"></span> {{ emailTemplate.name | ellipsis(64) }}</p>
                    <p class="mb-0 mt-1 text-microcopy--70 text-muted">
                        <ring size="14" :color="'#1070ca'" v-if="emailTemplateUpdating"></ring> {{ $t('Edited on') }} {{ emailTemplate.meta.lastModifiedDate | dateLiteral }} {{ $t('by') }} {{ emailTemplate.meta.lastModifiedBy }}
                    </p>
                </div>
                <div>
                    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar">
                        <div class="btn-group mr-2" role="group" aria-label="Email Template actions">
                            <button class="btn btn-light"
                                    :title="$t('Discard changes')"
                                    @click="confirmAndProceedToAction('discard')">
                                <i class="fas fa-undo-alt"></i>
                            </button>
                            <button class="btn btn-light"
                                    :title="$t('common_action_delete')"
                                    @click="confirmAndProceedToAction('delete')">
                                <i class="fas fa-trash"></i>
                            </button>
                        </div>
                        <div class="btn-group" role="group" aria-label="Email Template operations">
                            <button class="btn btn-primary"
                                    :title="$t('common_action_save')"
                                    @click="updateEmailTemplate">{{ $t('common_action_save') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Ring from '@/components/loading/Loaders/Ring';

/**
 * Header for EmailTemplate view.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 7 November 2020
 */
export default {
    name: 'EmailTemplateHeader',
    components: {
        Ring
    },
    data () {
        return {
            emailTemplateChooserOpen: false
        };
    },
    computed: {
        ...mapGetters('emailTemplate', {
            emailTemplate: 'emailTemplate',
            emailTemplateUpdating: 'emailTemplateUpdating'
        })
    },
    methods: {
        /**
         * Updates the EmailTemplate.
         */
        updateEmailTemplate () {
            this.$store.dispatch('emailTemplate/updateEmailTemplate')
                .then(() => {
                    this.toastDefault('Email Template updated');
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                });
        },

        /**
         * Display confirmation message box.
         * If user selects to proceed, it proceeds to the corresponding logic.
         *
         * @param action the action to confirm and proceed to.
         */
        confirmAndProceedToAction (action) {
            if (typeof action !== 'string') return;

            let originalTitle = 'Confirm Action';
            let originalMessage = '';
            if (action === 'discard') {
                originalTitle = 'Discard changes?';
                originalMessage = 'All changes will be discarded.';
            } else if (action === 'delete') {
                originalTitle = 'Delete Email Template?';
                originalMessage = null;
            } else {
                return;
            }

            const messageBoxConfiguration = {
                title: this.$t(originalTitle),
                message: this.$t(originalMessage),
                showCancelButton: true,
                confirmButtonText: this.$t('common_action_okay'),
                cancelButtonText: this.$t('common_action_cancel'),
                closeOnClickModal: false,
                closeOnPressEscape: true,
                roundButton: false,
                showClose: false,
                customClass: 'el-message-box--w400',
                lockScroll: false
            };

            this.$msgbox(messageBoxConfiguration)
                .then((response) => {
                    if (response !== 'confirm') return;

                    if (action === 'discard') {
                        this.$store.dispatch('emailTemplate/discardEmailTemplateChanges')
                            .then(() => {
                                this.$store.commit('emailTemplate/updateActiveComponentCommonKey');
                            })
                            .catch(() => void 0);
                    } else if (action === 'delete') {
                        this.$store.dispatch('emailTemplate/deleteEmailTemplate')
                            .then(() => {
                                // Update EntityQlu for this entity.
                                // 2020-03-13-@future-reduce-scattered-calls.
                                this.$store.dispatch('application/quotasFindOneEntityQlu', 'emailTemplate');
                                this.$store.commit('emailTemplate/updateActiveComponentCommonKey');
                                // Go to emailTemplates listing.
                                this.$router.push({ name: 'email-templates' });
                            })
                            .catch((reason) => {
                                this.toastErrorFromError(reason);
                            });
                    } else {} // Theoretically unreachable.
                })
                .catch(() => void 0);
        }
    }
};
</script>
